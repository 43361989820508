import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Approval, Close } from "@mui/icons-material";

import userAvatar from "../../Assets/Images/userAvatar.svg";
import collectionPlaceholderImage from "../../Assets/Images/collectionPlaceholderImage.svg";

import moment from "moment"

import "./CollectionCard.css";

const CollectionCard = ({
  collectionData,
  handleApproveLibrabry,
  loading,
  rejecthandlerLibray,
}) => {
  return collectionData.map((collection) => (
    <div className="collection-card p-4">
      <div className="header d-flex justify-content-between">
        <h5 className="collection-title fs-5 d-inline-block fw-bolder">
          {collection.name.length > 20
            ? collection.name.substr(0, 20) + "..."
            : collection.name}
        </h5>
        {/* <div className="like-font d-flex flex-nowrap"><span className="like-icon"><img src={likeIcon} alt="like-icon" /></span><span className="fw-500 ml-1">{250}</span></div> */}
      </div>
      <div>
        <div>
          <img
            src={
              collection.preview
                ? collection.preview
                : collectionPlaceholderImage
            }
            alt="collection-image"
            className="collection-img"
          />
        </div>
        <div className="collection-info d-flex mt-2">
          <div className="user-info d-flex">
            <div className="user-image">
              <img src={userAvatar} className="w-100 h-auto" alt="user-image" />
            </div>
            <div className="username ms-2">{collection.authors[0]?.name}</div>
          </div>
          <div className="date text-nowrap">{moment.utc(collection.created).local().format("YYYY-MM-DD")}</div>
        </div>
        <div className="mt-3 text-left">
          {collection.description.length > 65
            ? collection.description.substr(0, 65) + "..."
            : collection.description}
        </div>
      </div>

      <div className="footer ">
        <div className="btns d-flex gap-2 justify-content-end mt-2">
          <LoadingButton
            loading={loading[collection.id] || false}
            sx={{
              backgroundColor:
                collection.status === "Approved"
                  ? "Green"
                  : collection.status === "Rejected"
                  ? "red"
                  : "#585A96",
            }}
            loadingPosition="start"
            startIcon={<Approval />}
            variant="contained"
            onClick={() =>
              collection.status === "Under review" &&
              handleApproveLibrabry(collection.id)
            }
          >
            {collection.status === "Under review"
              ? "Approve"
              : collection.status}
          </LoadingButton>
          <a
            className="addToCollection rounded bg-white px-2 py-1 cursor-pointer text-decoration-none"
            href={`https://wb-lib-approval-view.azurewebsites.net?element=${collection.source}`}
            target="_blank"
          >
            Link
          </a>

          {collection.status === "Under review" && (
            <button
              className="addToCollection rounded bg-white px-2 py-1 cursor-pointer text-decoration-none"
              onClick={() => rejecthandlerLibray(collection.id)}
            >
              Reject
            </button>
          )}
        </div>
      </div>
    </div>
  ));
};

export default CollectionCard;
