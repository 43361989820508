import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../Header/Header";
import CircularProgress from "@mui/material/CircularProgress";

const CreditPackage = () => {
  const url = process.env.REACT_APP_BASEURL;
  const [creditPackage, setCreditPackage] = useState([]);
  const [msgData, setMsgData] = React.useState(null);
  const [load, setLoad] = useState(false);

  const handleAddInput = (type) => {
    setCreditPackage((prevState) => {
      return prevState.map((item) => {
        if (item.planName === type) {
          return {
            ...item,
            services: [...item.services, ""],
          };
        }
        return item;
      });
    });
  };

  const handleServicesChange = (value, key, index, innerIndex) => {
    let clonedCreditPackage = [...creditPackage];
    clonedCreditPackage[index][key][innerIndex] = value;
    setCreditPackage(clonedCreditPackage);
  };

  const handleDeleteInput = (planName, index) => {
    setCreditPackage((prevState) => {
      const packageIndex = prevState.findIndex(
        (item) => item.planName === planName
      );
      if (packageIndex !== -1) {
        const updatedServices = prevState[packageIndex].services.filter(
          (_, i) => i !== index
        );
        const updatedPackage = {
          ...prevState[packageIndex],
          services: updatedServices,
        };
        const deleteInputs = [
          ...prevState.slice(0, packageIndex),
          updatedPackage,
          ...prevState.slice(packageIndex + 1),
        ];

        onUpdateCreditPackage({
          field: "services",
          value: JSON.stringify(updatedPackage.services),
          planId: updatedPackage.planId,
        });
        return deleteInputs;
      }
      return prevState;
    });
  };

  const handleGetCreditPackage = () => {
    setLoad(true);
    axios
      .get(`${url}/api/credit/get-credit-packages`, {
        headers: { "x-access-token": window.sessionStorage.getItem("Token") },
      })
      .then((res) => {
        setLoad(false);
        const modifiedData = res?.data?.result?.map((item) => {
          const service = item.services !== "" ? JSON.parse(item.services) : [];
          return {
            ...item,
            services: service,
          };
        });
        setCreditPackage(modifiedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetCreditPackage();
  }, []);

  const handleUpdateCreditPackage = (plans, key, e, planName) => {
    const value =
      key === "services"
        ? JSON.stringify(
            creditPackage.find((item) => item.planName === planName)?.services
          )
        : e?.target?.innerText;
    const payload = {
      planId: plans,
      field: key,
      value: value,
    };
    onUpdateCreditPackage(payload);
  };

  const onUpdateCreditPackage = (payload) => {
    axios
      .put(`${url}/api/credit/update-credit-package`, payload, {
        headers: { "x-access-token": window.sessionStorage.getItem("Token") },
      })
      .then((res) => {
        setMsgData(
          {
            message: "Package has been updated successfully",
            type: "Success",
          } || null
        );
        setTimeout(() => {
          setMsgData(null);
        }, 1500);
      });
  };

  const showMessage = () => {
    return (
      <div
        className={
          msgData.type === "Success"
            ? "alert alert-success fixed-bottom alert-dismissible fade show"
            : "alert alert-danger fixed-bottom alert-dismissible fade show"
        }
        role="alert"
        style={{ zIndex: "9999" }}>
        {msgData.message}
        <button
          type="button"
          className="close"
          onClick={() =>
            document
              .getElementsByClassName("alert")[0]
              ?.classList.remove("show")
          }>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  };

  return (
    <>
      <Header />
      <div className="mx-4" style={{ marginTop: "72px" }}>
        {msgData ? showMessage() : null}
        <h5 className="py-4">Credit Packages</h5>
        {load ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : (
          <>
            <table className="table container">
              <thead>
                <tr className="table-secondary">
                  <th scope="row"></th>
                  {creditPackage?.length &&
                    creditPackage.map((item) => (
                      <th scope="row">{item?.planName}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {creditPackage?.length &&
                  Object.keys(creditPackage[0]).map((key, index) => {
                    return (
                      <tr>
                        {key !== "planId" &&
                          key !== "status" &&
                          key !== "planName" && (
                            <th className="topic" scope="row">
                              {key}
                            </th>
                          )}
                        {creditPackage.map((data, dataIndex) => {
                          return (
                            <>
                              {key === "services" ? (
                                <td>
                                  <div className="d-flex justify-content-center">
                                    <div className="d-grid">
                                      {data?.services?.length > 1 &&
                                        data?.services?.map((input, index) => (
                                          <div className="d-flex" key={index}>
                                            <input
                                              value={input}
                                              key={index}
                                              type="text"
                                              className={
                                                index !== 0 ? "mt-1" : ""
                                              }
                                              onChange={(e) => {
                                                handleServicesChange(
                                                  e.target.value,
                                                  key,
                                                  dataIndex,
                                                  index,
                                                  data?.planName
                                                );
                                              }}
                                              onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                  e.target.blur();
                                                  handleUpdateCreditPackage(
                                                    data?.planId,
                                                    key,
                                                    e,
                                                    data.planName
                                                  );
                                                }
                                              }}
                                            />
                                            <div className="d-flex align-items-center">
                                              <button
                                                type="button"
                                                className="btn btn-outline-secondary py-0 px-2 ml-2"
                                                onClick={() =>
                                                  handleDeleteInput(
                                                    data?.planName,
                                                    index
                                                  )
                                                }
                                                style={{
                                                  height: "fit-content",
                                                }}>
                                                -
                                              </button>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary py-0 px-2 ml-2"
                                        onClick={() =>
                                          handleAddInput(data.planName)
                                        }
                                        style={{ height: "fit-content" }}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              ) : (
                                key !== "planId" &&
                                key !== "planName" &&
                                key !== "status" && (
                                  <td>
                                    <div
                                      contentEditable
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                          e.target.blur();
                                          handleUpdateCreditPackage(
                                            data?.planId,
                                            key,
                                            e
                                          );
                                        }
                                      }}>
                                      {data[key]}
                                    </div>
                                  </td>
                                )
                              )}
                            </>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
};

export default CreditPackage;
