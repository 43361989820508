import React, { useEffect, useState } from "react";
import axios from "axios";

import Header from "../../Header/Header";

import "./Collections.css";
import CollectionCard from "../../CollectionCard/ColllectionCard";
import RejectCollectionDialog from "../../RejectCollectionDialog/RejectCollectionDialog";

const Collections = () => {
  const url = process.env.REACT_APP_COLLECTION_BASEURL;

  const [collectionData, setCollectionData] = useState([]);
  const [loading, setLoading] = useState({});
  const [open, setOpen] = useState(false);
  const [rejectCollection, setRejectCollection] = useState({
    id: "",
    reason: "",
  });

  const handleApproveLibrabry = async (id) => {
    setLoading((oldState) => ({ ...oldState, [id]: true }));
    try {
      const response = await fetch(`${url}/approve-collocation`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });
      const data = await response.json();
      fetchAllUnpublishedCollocation();
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    } catch (error) {
      console.log(error);
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    }
  };

  const rejecthandlerLibray = async (id) => {
    setRejectCollection({ ...rejectCollection, id });
    setOpen(true);
  };

  const fetchAllUnpublishedCollocation = () => {
    axios
      .get(`${url}/get-unpublished-collocation-for-admin`)
      .then((res) => {
        setCollectionData(res.data.result.resources);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const reasonInputChangehandler = (reasonText, id) => {
    setRejectCollection({ ...rejectCollection, reason: reasonText });
  };

  const handleReject = async (id) => {
    try {
      const response = await fetch(
        `${url}/reject-collection?id=${rejectCollection.id}`,
        {
          method: "DELETE",
          body: JSON.stringify({ reason: rejectCollection.reason }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      fetchAllUnpublishedCollocation();
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    } catch (error) {
      console.log(error);
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    fetchAllUnpublishedCollocation();
  }, []);

  return (
    <>
      <Header />
      <div className="mx-4 mt-58">
        <h3 className="py-3">Collections</h3>
        <div className="collection-container">
          {collectionData.length > 0 && (
            <CollectionCard
              collectionData={collectionData}
              // collection={collection}
              handleApproveLibrabry={handleApproveLibrabry}
              loading={loading}
              rejecthandlerLibray={rejecthandlerLibray}
            />
          )}
        </div>
        <RejectCollectionDialog
          open={open}
          handleClose={handleClose}
          reasonInputChangehandler={reasonInputChangehandler}
          handleReject={handleReject}
        />
      </div>
    </>
  );
};

export default Collections;
